//@flow
declare var d3: any
function emit(target, method, args) {
  for (const key in args) {
    target = target[method](key, args[key])
  }
  return target
}
function method(method, args) {
  return target => emit(target, method, args)
}
function fun(method) {
  return (target, args) => {
    if (args === undefined) {
      args = target
      return target => emit(target, method, args)
    }
    return emit(target, method, args)
  }
}
function batchRun(values) {
  for (const key in this) {
    if (key in values) {
      this[key](values[key])
    }
  }
}
function batch(api, values) {
  if (values) {
    return batchRun.bind(api, values)
  }
  return batchRun.bind(api)
}
const Fun = {
  on: fun('on'),
  attr: fun('attr'),
}
window.graphHandlers.dyn = function dyn(
  {
    baseNodes,
    baseLinks,
  }: {baseNodes: Array<*>, baseLinks: Array<*>} = window.graphData,
) {
  const nodes = [...baseNodes]
  let links = baseLinks.map(b => ({...b}))
  function getNeighbors({id}) {
    return baseLinks.reduce(
      (neighbors, {target, source}) => {
        if (target.id === id) {
          neighbors.push(source.id)
        } else if (source.id === id) {
          neighbors.push(target.id)
        }
        return neighbors
      },
      [id],
    )
  }
  function isNeighborLink({id}, {target, source}) {
    return target.id === id || source.id === id
  }
  function getNodeColor({id, level}, neighbors) {
    if (Array.isArray(neighbors) && neighbors.includes(id)) {
      return level === 1 ? 'blue' : 'green'
    }
    return level === 1 ? 'red' : 'gray'
  }
  function getLinkColor(node, link) {
    return isNeighborLink(node, link) ? 'green' : '#E5E5E5'
  }
  function getTextColor({id}, neighbors) {
    return Array.isArray(neighbors) && neighbors.includes(id)
      ? 'green'
      : 'black'
  }

  const width = window.innerWidth
  const height = window.innerHeight
  const svg = d3.select('svg')
  svg.attr('width', width).attr('height', height)
  let linkElements
  let nodeElements
  let textElements
  const gMain = svg.append('g')
  const gDraw = gMain.append('g')
  // we use svg groups to logically group the elements together
  const linkGroup = gDraw.append('g').attr('class', 'links')
  const textGroup = gDraw.append('g').attr('class', 'texts')
  const nodeGroup = gDraw.append('g').attr('class', 'nodes')

  gMain.call(
    d3.zoom().on('zoom', () => gDraw.attr('transform', d3.event.transform)),
  )

  // we use this reference to select/deselect
  // after clicking the same element twice
  let selectedId

  const simulation = d3
    .forceSimulation()
    .force(
      'link',
      d3
        .forceLink()
        .id(({id}) => id)
        .distance(d => {
          //var dist = 20 / d.value;
          // console.log('dist:', d)
          // if (d.source.group === d.target.group) return -5
          // return -15

          return 5
        })
        .strength(({strength}) => strength + 1),
    )
    .force('charge', d3.forceManyBody().strength(-60))
    .force('center', d3.forceCenter(width / 2, height / 2))
    .force('x', d3.forceX(width / 2))
    .force('y', d3.forceY(height / 2))
  // const simulation = d3
  //   .forceSimulation()
  //   // simulation setup with all forces
  //   .force(
  //     'link',
  //     d3
  //       .forceLink()
  //       .id(({id}) => id)
  //       .strength(({strength}) => strength),
  //   )
  //   .force('charge', d3.forceManyBody().strength(-10))
  //   .force('center', d3.forceCenter(width / 2, height / 2))
  const dragDrop = Fun.on(d3.drag(), {
    start(node) {
      node.fx = node.x
      node.fy = node.y
    },
    drag(node) {
      simulation.alphaTarget(0.7).restart()
      node.fx = d3.event.x
      node.fy = d3.event.y
    },
    end(node) {
      if (!d3.event.active) {
        simulation.alphaTarget(0)
      }
      node.fx = null
      node.fy = null
    },
  })

  // this helper simple adds all nodes and links
  // that are missing, to recreate the initial state
  function resetData() {
    const nodeIds = nodes.map(({id}) => id)
    baseNodes.forEach(node => {
      if (!nodeIds.includes(node.id)) {
        nodes.push(node)
      }
    })
    links = baseLinks
  }
  // diffing and mutating the data
  function updateData(selectedNode) {
    const neighbors = getNeighbors(selectedNode)
    const newNodes = baseNodes.filter(
      ({id, level}) => neighbors.includes(id) || level === 1,
    )
    const diff = {
      removed: nodes.filter(node => !newNodes.includes(node)),
      added: newNodes.filter(node => !nodes.includes(node)),
    }
    diff.removed.forEach(node => {
      nodes.splice(nodes.indexOf(node), 1)
    })
    diff.added.forEach(node => {
      nodes.push(node)
    })
    links = baseLinks.filter(
      ({target, source}) =>
        target.id === selectedNode.id || source.id === selectedNode.id,
    )
  }
  function updateGraph() {
    // links
    linkElements = linkGroup
      .selectAll('line')
      .data(links, ({target, source}) => target.id + source.id)
    linkElements.exit().remove()
    const linkEnter = linkElements
      .enter()
      .append('line')
      .attr('stroke-width', 1)
      .attr('stroke', 'rgba(50, 50, 50, 0.2)')
    linkElements = linkEnter.merge(linkElements)
    // nodes
    nodeElements = nodeGroup.selectAll('circle').data(nodes, ({id}) => id)
    nodeElements.exit().remove()
    const nodeEnter = nodeElements
      .enter()
      .append('circle')
      .attr('r', 4)
      .attr('fill', ({level}) => (level === 1 ? 'red' : 'gray'))
      .call(dragDrop)
      // we link the selectNode method here
      // to update the graph on every click
      // select node is called on every click
      // we either update the data according to the selection
      // or reset the data if the same node is clicked twice
      .on('click', selectedNode => {
        if (selectedId === selectedNode.id) {
          selectedId = undefined
          resetData()
          updateSimulation()
        } else {
          selectedId = selectedNode.id
          // updateData(selectedNode)
          updateSimulation()
        }
        const neighbors = getNeighbors(selectedNode)
        // we modify the styles to highlight selected nodes
        nodeElements.attr('fill', node => getNodeColor(node, neighbors))
        textElements.attr('fill', node => getTextColor(node, neighbors))
        linkElements.attr('stroke', link => getLinkColor(selectedNode, link))
      })
    nodeElements = nodeEnter.merge(nodeElements)
    // texts
    textElements = textGroup.selectAll('text').data(nodes, ({id}) => id)
    textElements.exit().remove()
    const textEnter = textElements
      .enter()
      .append('text')
      .text(({label}) => label)
      .attr('font-size', 10)
      .attr('dx', 15)
      .attr('dy', 4)
    textElements = textEnter.merge(textElements)
  }
  const onTickUpdate = batch({
    node: Fun.attr({
      cx: ({x}) => x,
      cy: ({y}) => y,
    }),
    text: Fun.attr({
      x: ({x}) => x,
      y: ({y}) => y,
    }),
    link: Fun.attr({
      x1: ({source}) => source.x,
      y1: ({source}) => source.y,
      x2: ({target}) => target.x,
      y2: ({target}) => target.y,
    }),
  })
  function updateSimulation() {
    updateGraph()
    simulation.nodes(nodes).on('tick', () => {
      onTickUpdate({
        node: nodeElements,
        text: textElements,
        link: linkElements,
      })
    })
    simulation.force('link').links(links)
    simulation.alphaTarget(0.7).restart()
  }
  // last but not least, we call updateSimulation
  // to trigger the initial render
  updateSimulation()
}
